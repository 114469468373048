<!--
 * @Author: your name
 * @Date: 2021-12-09 11:35:47
 * @LastEditTime: 2021-12-23 16:11:33
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \dataview-viewer-test\src\views\qualityMeter\components\customForm.vue
-->

<template>
  <div class="custom-form" v-loading="addLoading">
    <FormDesigner v-if="!isAdd" :formUuid="formUuid" @form-data="formData"></FormDesigner>
    <div v-if="isAdd" class="switch-show">
      <div>
        <img src="@/assets/images/flow.jpg" alt="">
        <p v-if="isAdd">暂未绑定功能表单或者请创建功能表单！</p>
        <el-button v-if="isAdd" type="primary" class="button-class" @click="addForm">创建功能表单</el-button>
      </div>
    </div>
  </div>
</template>
<script>
// import { dataInterface } from "@/apis/data/index";FormDesigner
import FormDesigner from "@/custom-component/form/FormDesigner";
import {submitFromValue, editFromValue, getFromValue} from "@/apis/data/customForm";
import {dataInterface} from "@/apis/data";

export default {
  components: {FormDesigner},
  data() {
    return {
      formDesignId: "",
      formUuid: "",
      detailInfo: null,
      formInfo: {},
      addLoading: false,
      isAdd: true
    };
  },
  props: {
    tableInfo: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  computed: {},
  watch: {},
  mounted() {
    this.getInfo()
  },
  methods: {
    // 请求数据详情  判断是否有表单数据
    async getInfo() {
      let params = {
        __method_name__: "dataInfo",
        object_uuid: "object626118627602e",
        transcode: 0,
        data_id: this.tableInfo.id,
      };
      this.addLoading = true
      this.detailInfo = await this.dataInterface(params)
      if (this.detailInfo.module_uuid) {
        this.formUuid = this.detailInfo.module_uuid
        this.getFormData(this.formUuid)
      } else {
        this.isAdd = true
        this.addLoading = false
      }
    },
    // 请求数据id的详情
    async dataInterface(params) {
      let info = await dataInterface(params);
      return info.data.data
    },
    // 根据表单id  获取表单数据
    getFormData(id) {
      getFromValue({}, id).then(res => {
        this.formInfo = res.data.data
        this.isAdd = false
        this.addLoading = false
      })
    },
    // 新增表单
    async addForm() {
      this.addLoading = true
      let formParams = {
        module_name: this.detailInfo.name ? this.detailInfo.name : this.tableInfo.name
      }
      // module_name新增表单
      await submitFromValue(formParams).then(async (res) => {
        this.newFormUuid = res.data.data.module_uuid
        this.formDesignId = res.data.data.id
        let params = {
          __method_name__: "updateData",
          object_uuid: "object626118627602e",
          data_id: this.tableInfo.id,
          form_design_id: this.formDesignId,
          module_uuid: this.newFormUuid,
          sheet_uuid: this.detailInfo?.sheet_uuid
        };
        // 拿到新增表单  将新增表单绑定给数据id
        this.detailInfo = await this.dataInterface(params)
        this.getInfo()
      });
    },
    formData(data) {
      this.addLoading = true
      let formParams = {
        json: data.json,
        module_name: this.detailInfo.name,
        need_flow: this.formInfo.need_flow ? this.formInfo.need_flow : 0
      };
      editFromValue(formParams, this.formUuid ? this.formUuid : this.newFormUuid).then((res) => {
        if(res.data.code === 200){
          this.$message.success(res.data.msg)
        }
        this.getInfo()
      });
    }
  },
};
</script>
<style lang="less" scoped>
.custom-form {
  width: 100%;
  height: 100%;
}

.switch-show {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  p {
    color: #999;
    font-size: 16px;
    text-align: center;
    margin: 5px 0;
  }

  .button-class {
    margin-top: 10px;
  }
}
</style>
