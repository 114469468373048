import { render, staticRenderFns } from "./customForm.vue?vue&type=template&id=30a38ee5&scoped=true&"
import script from "./customForm.vue?vue&type=script&lang=js&"
export * from "./customForm.vue?vue&type=script&lang=js&"
import style0 from "./customForm.vue?vue&type=style&index=0&id=30a38ee5&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30a38ee5",
  null
  
)

export default component.exports